import React, { useEffect, useState } from 'react';
import { ThemeProvider, CssBaseline, Container, Box, Typography, Button, TextField, Grid, IconButton, Snackbar, Alert, CircularProgress } from '@mui/material';
import { motion, useScroll, useSpring } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { StyledEngineProvider } from '@mui/material/styles';
import { IconType } from 'react-icons';
import { theme } from './theme';
import './App.css';
import { TechStack, IconWrapper } from './components/TechLogos';

const FadeInSection: React.FC<{ children: React.ReactNode; delay?: number }> = ({ children, delay = 0 }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.8, delay }}
    >
      {children}
    </motion.div>
  );
};

const FloatingElement: React.FC<{ children: React.ReactNode; index: number }> = ({ children, index }) => {
  return (
    <motion.span
      initial={{ opacity: 0, y: 20 }}
      animate={{ 
        opacity: 1, 
        y: 0,
        transition: {
          duration: 0.5,
          delay: index * 0.1
        }
      }}
      whileHover={{
        scale: 1.2,
        color: '#00f5d4',
        transition: { duration: 0.2 }
      }}
      style={{ 
        display: 'inline-block',
        margin: '0 2px',
        textShadow: '0 0 10px rgba(0,245,212,0.3)'
      }}
    >
      {children}
    </motion.span>
  );
};

const ScrollProgress: React.FC = () => {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });

  return (
    <motion.div
      className="progress-bar"
      style={{
        scaleX,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        height: 3,
        background: 'linear-gradient(90deg, #00f5d4, #9d4edd)',
        transformOrigin: '0%',
        zIndex: 1000
      }}
    />
  );
};

const CursorGlow: React.FC = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return (
    <motion.div
      className="cursor-glow"
      style={{
        position: 'fixed',
        left: mousePosition.x,
        top: mousePosition.y,
        pointerEvents: 'none',
        zIndex: 9999
      }}
    />
  );
};

function App() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    projectDetails: ''
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error'
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    // API URL - use environment variable in production or fallback to local development
    const API_URL = process.env.REACT_APP_API_URL || 'https://your-backend-service.onrender.com';

    try {
      // Send form data to our backend API
      const response = await fetch(`${API_URL}/api/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          projectDetails: formData.projectDetails
        })
      });

      const data = await response.json();

      if (response.ok && data.success) {
        setSnackbar({
          open: true,
          message: 'Thank you for your message. We will be in touch shortly!',
          severity: 'success'
        });

        // Clear form
        setFormData({
          name: '',
          email: '',
          projectDetails: ''
        });
      } else {
        throw new Error(data.message || 'Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSnackbar({
        open: true,
        message: 'There was an error sending your message. Please try again.',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleScroll = () => {
    const techStackSection = document.getElementById('tech-stack');
    if (techStackSection) {
      techStackSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CursorGlow />
        <ScrollProgress />
        <Box
          sx={{
            minHeight: '100vh',
            background: `linear-gradient(135deg, ${theme.palette.background.default} 0%, #1a1a1a 100%)`,
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {/* Background Grid Animation */}
          <div className="grid-background" />
          
          <Container maxWidth="lg">
            {/* Hero Section */}
            <Box
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                position: 'relative',
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  background: 'linear-gradient(45deg, #00f5d4 30%, #9d4edd 90%)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                  mb: 3,
                  fontSize: { xs: '3rem', md: '4.5rem' },
                  fontWeight: 'bold',
                  letterSpacing: '2px',
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: -10,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '60%',
                    height: '2px',
                    background: 'linear-gradient(90deg, transparent, #00f5d4, transparent)',
                  }
                }}
              >
                {Array.from('WP-HELPDESK').map((letter, index) => (
                  <FloatingElement key={index} index={index}>
                    {letter}
                  </FloatingElement>
                ))}
              </Typography>
              <Typography
                variant="h2"
                component={motion.h2}
                sx={{ 
                  mb: 4,
                  color: 'grey.100',
                  fontSize: { xs: '1.5rem', md: '2.5rem' },
                  textShadow: '0 0 10px rgba(0,245,212,0.3)'
                }}
              >
                From sleek brochure sites to cutting-edge web apps and AI agents – we build it all. Get a website that wows and works             </Typography>
              <motion.div
                animate={{ y: [0, 10, 0] }}
                transition={{ duration: 2, repeat: Infinity }}
                style={{ position: 'absolute', bottom: 40 }}
              >
                <IconButton
                  onClick={handleScroll}
                  sx={{
                    color: 'primary.main',
                    border: '2px solid',
                    borderColor: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'primary.main',
                      color: 'background.default',
                    },
                  }}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </motion.div>
            </Box>

            {/* Tech Stack Section */}
            <FadeInSection>
              <Box id="tech-stack" sx={{ py: 10 }}>
                <Typography variant="h2" sx={{ mb: 6, textAlign: 'center' }}>
                  Our Tech Stack
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                  {TechStack.map((tech, index) => (
                    <Grid item key={tech.name}>
                      <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <Box
                          sx={{
                            width: 150,
                            height: 150,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                            background: `linear-gradient(135deg, rgba(18, 18, 18, 0.8) 0%, rgba(${tech.color}, 0.1) 100%)`,
                            borderRadius: '20px',
                            border: '1px solid',
                            borderColor: 'rgba(255,255,255,0.1)',
                            backdropFilter: 'blur(10px)',
                            position: 'relative',
                            overflow: 'hidden',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              borderColor: `rgba(${tech.color}, 1)`,
                              boxShadow: `0 0 20px rgba(${tech.color}, 0.3)`,
                            }
                          }}
                        >
                          <IconWrapper 
                            Icon={tech.Icon}
                            size={50}
                            color={`rgb(${tech.color})`}
                          />
                          <Typography
                            variant="h6"
                            sx={{
                              color: 'white',
                              fontWeight: 600,
                              textShadow: `0 0 10px rgba(${tech.color}, 0.5)`,
                            }}
                          >
                            {tech.name}
                          </Typography>
                        </Box>
                      </motion.div>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </FadeInSection>

            {/* Why Choose Us Section */}
            <FadeInSection>
              <Box sx={{ py: 10 }}>
                <Typography variant="h2" sx={{ mb: 6, textAlign: 'center' }}>
                  Why Choose WP-HELPDESK?
                </Typography>
                <Grid container spacing={4} sx={{ mb: 8 }}>
                  {[
                    {
                      title: 'AI-Powered Solutions',
                      description: 'Harness the power of artificial intelligence to automate tasks, enhance user experience, and drive business growth. From chatbots to predictive analytics, we integrate cutting-edge AI technology into your digital solutions.'
                    },
                    {
                      title: 'Custom Web Applications',
                      description: 'Transform your business ideas into powerful, scalable web applications. Whether you need an e-commerce platform, customer portal, or business management system, we build solutions that streamline your operations.'
                    },
                    {
                      title: 'Full-Stack Development',
                      description: 'Get the complete package with our end-to-end development expertise. From stunning front-end interfaces to robust back-end systems, we handle every layer of your project. Plus, we provide professional graphics, secure hosting, custom email solutions, and more.'
                    },
                    {
                      title: 'Ongoing Support',
                      description: 'Rest easy knowing your digital assets are in good hands. We provide comprehensive maintenance, security updates, and technical support to keep your website running smoothly.'
                    }
                  ].map((feature, index) => (
                    <Grid item xs={12} md={6} key={feature.title}>
                      <motion.div
                        whileHover={{ scale: 1.02 }}
                        transition={{ type: 'spring', stiffness: 300 }}
                      >
                        <Box
                          sx={{
                            p: 4,
                            borderRadius: 4,
                            bgcolor: 'background.paper',
                            height: '100%',
                            border: '1px solid',
                            borderColor: 'rgba(255,255,255,0.1)',
                            backdropFilter: 'blur(10px)',
                            position: 'relative',
                            overflow: 'hidden',
                            '&::after': {
                              content: '""',
                              position: 'absolute',
                              top: 0,
                              right: 0,
                              width: '4px',
                              height: '100%',
                              background: 'linear-gradient(180deg, #00f5d4, #9d4edd)',
                            }
                          }}
                        >
                          <Typography variant="h5" sx={{ mb: 2, color: 'primary.main' }}>
                            {feature.title}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            {feature.description}
                          </Typography>
                        </Box>
                      </motion.div>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </FadeInSection>

            {/* Statistics Section */}
            <FadeInSection>
              <Box 
                sx={{ 
                  py: 10,
                  background: 'linear-gradient(135deg, rgba(0,245,212,0.1) 0%, rgba(157,78,221,0.1) 100%)',
                  borderRadius: 4,
                  mb: 10
                }}
              >
                <Grid container spacing={4} justifyContent="center">
                  {[
                    { number: '500+', label: 'Projects Delivered' },
                    { number: '100%', label: 'Client Satisfaction' },
                    { number: '24/7', label: 'Support Available' },
                    { number: '350+', label: 'Happy Clients' }
                  ].map((stat, index) => (
                    <Grid item xs={6} md={3} key={stat.label}>
                      <Box
                        sx={{
                          textAlign: 'center',
                          p: 3,
                        }}
                      >
                        <Typography 
                          variant="h3" 
                          sx={{ 
                            mb: 1,
                            background: 'linear-gradient(45deg, #00f5d4 30%, #9d4edd 90%)',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            color: 'transparent',
                            fontWeight: 'bold'
                          }}
                        >
                          {stat.number}
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                          {stat.label}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </FadeInSection>

            {/* Call to Action Section */}
            <FadeInSection>
              <Box
                sx={{
                  py: 10,
                  px: { xs: 2, md: 8 },
                  textAlign: 'center',
                  mb: 10,
                  position: 'relative',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'linear-gradient(135deg, rgba(0,245,212,0.1) 0%, rgba(157,78,221,0.1) 100%)',
                    borderRadius: 4,
                    zIndex: -1
                  }
                }}
              >
                <Typography variant="h3" sx={{ mb: 3 }}>
                  Ready to Transform Your Digital Presence?
                </Typography>
                <Typography variant="h5" sx={{ mb: 4, color: 'text.secondary' }}>
                  Join the ranks of successful businesses that have elevated their online presence with our expertise.
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => {
                    const contactForm = document.querySelector('form');
                    contactForm?.scrollIntoView({ behavior: 'smooth' });
                  }}
                  sx={{
                    background: 'linear-gradient(45deg, #00f5d4 30%, #9d4edd 90%)',
                    px: 6,
                    py: 2,
                    fontSize: '1.2rem',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #00ab94 30%, #6d359a 90%)',
                    }
                  }}
                >
                  Start Your Journey Today
                </Button>
              </Box>
            </FadeInSection>

            {/* Services Section */}
            <FadeInSection>
              <Box sx={{ py: 10 }}>
                <Typography variant="h2" sx={{ mb: 6, textAlign: 'center' }}>
                  Breaking the Norm in Web Design
                </Typography>
                <Grid container spacing={4}>
                  {[
                    {
                      title: 'Modern Design',
                      description: 'Transform your online presence with stunning, contemporary designs that captivate your audience. We craft visually striking websites with intuitive user experiences, ensuring your brand stands out in today\'s digital landscape. From sleek animations to responsive layouts, we build websites that leave lasting impressions.'
                    },
                    {
                      title: 'Responsive Development',
                      description: 'Future-proof your digital presence with cutting-edge web applications that work flawlessly across all devices. Our expert developers utilize the latest technologies like React, Node.js, and AI integration to build powerful, scalable solutions that grow with your business. Experience lightning-fast performance and robust functionality.'
                    },
                    {
                      title: 'Brand Strategy',
                      description: 'Elevate your brand\'s digital footprint with our comprehensive strategy approach. We don\'t just build websites; we create digital experiences that align with your business goals. From SEO optimization to conversion-focused design, we ensure your website becomes your most powerful marketing tool.'
                    }
                  ].map((service, index) => (
                    <Grid item xs={12} md={4} key={service.title}>
                      <motion.div
                        whileHover={{ scale: 1.05 }}
                        transition={{ type: 'spring', stiffness: 300 }}
                      >
                        <Box
                          sx={{
                            p: 4,
                            borderRadius: 4,
                            bgcolor: 'background.paper',
                            height: '100%',
                            position: 'relative',
                            overflow: 'hidden',
                            border: '1px solid',
                            borderColor: 'rgba(255,255,255,0.1)',
                            backdropFilter: 'blur(10px)',
                            '&::before': {
                              content: '""',
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              background: 'linear-gradient(45deg, transparent, rgba(255,255,255,0.1), transparent)',
                              transform: 'translateX(-100%)',
                            },
                            '&:hover::before': {
                              transform: 'translateX(100%)',
                              transition: 'transform 0.6s',
                            },
                          }}
                        >
                          <Typography variant="h5" sx={{ mb: 2, color: 'primary.main' }}>
                            {service.title}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            {service.description}
                          </Typography>
                        </Box>
                      </motion.div>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </FadeInSection>

            {/* Contact Form */}
            <FadeInSection>
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                  py: 10,
                  px: { xs: 2, md: 8 },
                  bgcolor: 'background.paper',
                  borderRadius: 4,
                  mb: 10,
                  position: 'relative',
                  overflow: 'hidden',
                  border: '1px solid',
                  borderColor: 'rgba(255,255,255,0.1)',
                  backdropFilter: 'blur(10px)',
                }}
              >
                <Typography variant="h2" sx={{ mb: 6, textAlign: 'center' }}>
                  Start Your Project
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      variant="outlined"
                      required
                      disabled={loading}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'rgba(255,255,255,0.1)',
                          },
                          '&:hover fieldset': {
                            borderColor: 'primary.main',
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      type="email"
                      variant="outlined"
                      required
                      disabled={loading}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'rgba(255,255,255,0.1)',
                          },
                          '&:hover fieldset': {
                            borderColor: 'primary.main',
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Project Details"
                      name="projectDetails"
                      value={formData.projectDetails}
                      onChange={handleChange}
                      multiline
                      rows={4}
                      variant="outlined"
                      required
                      disabled={loading}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'rgba(255,255,255,0.1)',
                          },
                          '&:hover fieldset': {
                            borderColor: 'primary.main',
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      disabled={loading}
                      sx={{
                        mt: 2,
                        background: 'linear-gradient(45deg, #00f5d4 30%, #9d4edd 90%)',
                        position: 'relative',
                        overflow: 'hidden',
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          background: 'linear-gradient(45deg, transparent, rgba(255,255,255,0.2), transparent)',
                          transform: 'translateX(-100%)',
                        },
                        '&:hover': {
                          background: 'linear-gradient(45deg, #00ab94 30%, #6d359a 90%)',
                          '&::before': {
                            transform: 'translateX(100%)',
                            transition: 'transform 0.6s',
                          },
                        },
                      }}
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Let's Create Something Amazing"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </FadeInSection>

            <Snackbar 
              open={snackbar.open} 
              autoHideDuration={6000} 
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert 
                onClose={handleCloseSnackbar} 
                severity={snackbar.severity}
                sx={{ width: '100%' }}
              >
                {snackbar.message}
              </Alert>
            </Snackbar>
          </Container>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
