import React from 'react';
import { IconType, IconBaseProps } from 'react-icons';
import { 
  SiReact, 
  SiJavascript, 
  SiTypescript, 
  SiNodedotjs, 
  SiPython, 
  SiPhp, 
  SiMysql, 
  SiMongodb,
  SiOpenjdk,
  SiGo,
  SiAmazon,
  SiDocker
} from 'react-icons/si';

interface IconWrapperProps extends Omit<IconBaseProps, 'ref'> {
  Icon: React.ComponentType<IconBaseProps>;
}

const IconWrapper = ({ Icon, ...props }: IconWrapperProps): React.ReactElement => {
  return <Icon {...props} />;
};

interface TechStackItem {
  name: string;
  color: string;
  Icon: React.ComponentType<IconBaseProps>;
}

const TechLogos = {
  React: SiReact,
  JavaScript: SiJavascript,
  TypeScript: SiTypescript,
  Node: SiNodedotjs,
  Python: SiPython,
  PHP: SiPhp,
  MySQL: SiMysql,
  MongoDB: SiMongodb,
  Java: SiOpenjdk,
  Go: SiGo,
  AWS: SiAmazon,
  Docker: SiDocker
} as const;

export const TechStack: TechStackItem[] = [
  { name: 'React', color: '97, 218, 251', Icon: TechLogos.React as React.ComponentType<IconBaseProps> },
  { name: 'JavaScript', color: '240, 219, 79', Icon: TechLogos.JavaScript as React.ComponentType<IconBaseProps> },
  { name: 'TypeScript', color: '49, 120, 198', Icon: TechLogos.TypeScript as React.ComponentType<IconBaseProps> },
  { name: 'Node.js', color: '131, 205, 41', Icon: TechLogos.Node as React.ComponentType<IconBaseProps> },
  { name: 'Python', color: '55, 118, 171', Icon: TechLogos.Python as React.ComponentType<IconBaseProps> },
  { name: 'PHP', color: '137, 147, 190', Icon: TechLogos.PHP as React.ComponentType<IconBaseProps> },
  { name: 'MySQL', color: '0, 97, 138', Icon: TechLogos.MySQL as React.ComponentType<IconBaseProps> },
  { name: 'MongoDB', color: '67, 153, 52', Icon: TechLogos.MongoDB as React.ComponentType<IconBaseProps> },
  { name: 'Java', color: '236, 69, 31', Icon: TechLogos.Java as React.ComponentType<IconBaseProps> },
  { name: 'Go', color: '0, 173, 216', Icon: TechLogos.Go as React.ComponentType<IconBaseProps> },
  { name: 'AWS', color: '255, 153, 0', Icon: TechLogos.AWS as React.ComponentType<IconBaseProps> },
  { name: 'Docker', color: '33, 136, 255', Icon: TechLogos.Docker as React.ComponentType<IconBaseProps> }
];

export { IconWrapper }; 